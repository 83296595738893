<template>
    <title-bar :title="$t('navigations.navisionTypes')"/>
    <resource-listing :resource="$EntityOptions" param-type="NAVISION_TYPE" base-url="navision-types" add-button="messages.addNew" delete-message="messages.navisionTypeDelete"/>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ResourceListing from '../components/ResourceListing.vue'

export default {
    components: {
        TitleBar,
        ResourceListing
    },
}
</script>
